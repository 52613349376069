import { createSelector } from 'reselect';

import { defaultFeatures } from 'hooks/useFeatureFlags';
import { findCategoryWithParentLabel } from 'utils/helpers';

import { PerxState } from 'MyTypes';

const selectGlobal = (state: PerxState) => state.global;

const selectRoute = state => state.route;

const makeSelectCurrentUser = () =>
  createSelector(selectGlobal, globalState => globalState.currentUser);

const makeSelectAuthUser = () => createSelector(selectGlobal, globalState => globalState.authUser);

//@ts-ignore this points to initURL on global state which is not defined, but this selector does not seem to be used anywhere
const makeSelectInitURL = () => createSelector(selectGlobal, globalState => globalState.initURL);

const makeSelectLoading = () => createSelector(selectGlobal, globalState => globalState.loading);

const makeSelectError = () => createSelector(selectGlobal, globalState => globalState.error);
const makeSelectTokenError = () =>
  createSelector(selectGlobal, globalState => globalState.tokenError);

const makeSelectLocation = () => createSelector(selectRoute, routeState => routeState.location);

const makeSelectTenantConfig = () =>
  createSelector(selectGlobal, globalState => globalState.tenantConfig);

const makeSelectBulkActions = () =>
  createSelector(selectGlobal, globalState => globalState.bulkActions);

const makeSelectAvailableLocales = () =>
  createSelector(selectGlobal, globalState => globalState?.availableLocales?.locales);

const makeSelectSelectedLocale = () =>
  createSelector(selectGlobal, globalState => globalState?.selectedLocale);

const makeSelectReportDownloads = () =>
  createSelector(selectGlobal, globalState => globalState.reportDownloads);

const makeSelectDefaultCurrency = () =>
  createSelector(selectGlobal, globalState => globalState.currency);

const makeSelectCustomerPendingRequest = () =>
  createSelector(selectGlobal, globalState => globalState.customerPendingRequest);

const makeSelectCampaignPendingRequest = () =>
  createSelector(selectGlobal, globalState => globalState.campaignPendingRequest);

const makeSelectRewardPendingRequest = () =>
  createSelector(selectGlobal, globalState => globalState.rewardPendingRequest);

const makeSelectRulePendingRequest = () =>
  createSelector(selectGlobal, globalState => globalState.rulePendingRequest);

const makeSelectIsValidPassword = createSelector(
  [selectGlobal],
  globalState => globalState.validReset
);

const makeSelectIsEmailSentOut = createSelector(
  [selectGlobal],
  globalState => globalState.isEmailSent
);

const makeSelectResetPassword = createSelector([selectGlobal], globalState => globalState.password);

const makeSelectInitChangevalidToken = createSelector(
  [selectGlobal],
  globalState => globalState.initValidInviteChange
);

const makeSelectInitPasswordChanged = createSelector(
  [selectGlobal],
  globalState => globalState.initPwdChange
);

const makeFeatureSelector = () =>
  createSelector(selectGlobal, globalState => {
    const features = globalState?.tenantConfig?.features || {};
    Object.keys(defaultFeatures).forEach(key => {
      if (features[key] === undefined) {
        features[key] = defaultFeatures[key];
      }
    });
    return features;
  });

const makeSelectCategory = () =>
  createSelector(
    (state: PerxState) => state.global.categories.data,
    (_, id) => id,
    (categories, id) => {
      const { found, parentLabel: parentCategory } = findCategoryWithParentLabel(
        categories,
        id,
        'title'
      );
      if (found) {
        return {
          ...found,
          ...(parentCategory && { parentCategory })
        };
      }
    }
  );

const makeSelectLabel = () =>
  createSelector(
    (state: PerxState) => state.global.labels.data,
    (_, id) => id,
    (labels, id) => {
      // console.log('labels', labels, 'id', id);
      const { found, parentLabel } = findCategoryWithParentLabel(labels, id, 'title');
      if (found) {
        return {
          ...found,
          ...(parentLabel && { parentLabel })
        };
      }
      return null;
    }
  );

const selectPermissions = state => state.permissions;

const makeActionsSelector = () =>
  createSelector(
    selectPermissions,
    (_, resource_name) => resource_name,
    (permissions, resource_name) => {
      return permissions.permissions[resource_name]?.actions || [];
    }
  );

const makeRestrictedFieldsSelector = () =>
  createSelector(
    selectPermissions,
    (_, resource_name) => resource_name,
    (permissions, resource_name) => permissions.permissions[resource_name]?.restricted_fields || []
  );

const makeSelectIsRoleTenantAdmin = () =>
  createSelector(selectGlobal, globalState => globalState.isRoleTenantAdmin);

export {
  selectGlobal,
  makeSelectLabel,
  makeSelectCategory,
  makeSelectResetPassword,
  makeSelectIsEmailSentOut,
  makeSelectIsValidPassword,
  makeSelectInitPasswordChanged,
  makeSelectInitChangevalidToken,
  makeSelectCurrentUser,
  makeSelectAuthUser,
  makeSelectInitURL,
  makeSelectLoading,
  makeSelectError,
  makeSelectTokenError,
  makeSelectLocation,
  makeSelectTenantConfig,
  makeSelectAvailableLocales,
  makeSelectSelectedLocale,
  makeSelectReportDownloads,
  makeSelectBulkActions,
  makeSelectDefaultCurrency,
  makeActionsSelector,
  makeRestrictedFieldsSelector,
  makeSelectCustomerPendingRequest,
  makeSelectCampaignPendingRequest,
  makeSelectRewardPendingRequest,
  makeSelectRulePendingRequest,
  makeFeatureSelector,
  makeSelectIsRoleTenantAdmin
};
