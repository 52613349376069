import { isEqual, forEach, has, get, set, isNil } from 'lodash';

import { ERROR_CODES, ERROR_MESSAGES } from 'appConstants';
import { useTenant } from 'hooks';

export const useLookupTableValue = () => {
  const tenant = useTenant();

  const isLookupTableAllowed = ['btpn', 'btpn_preproduction', 'btpn_production', 'ocbc'].includes(
    tenant?.name
  );

  return isLookupTableAllowed;
};

/**
 * Get differences between current and payload objects.
 * @param {Record<string, any>} current - The base object to compare against.
 * @param {Record<string, any>} payload - The object containing potential updates.
 * @returns {Record<string, any>} - An object containing only the keys from the payload that are new or different from the current object.
 */
export const getDiff = (
  current: Record<string, any>,
  payload: Record<string, any>
): Record<string, any> => {
  // Initialize an empty object to store the differences
  const diff: Record<string, any> = {};

  // Iterate over the keys in the payload object
  forEach(payload, (value: any, key: string) => {
    // Check if the key exists in the current object and if the values are different
    if (!has(current, key) || !isEqual(value, get(current, key))) {
      // If the key is new or the value is different, add it to the diff object
      diff[key] = value;
    }
  });

  // Use case for `og_image` attribute
  if (has(current, 'og_image') && !has(payload, 'og_image')) {
    set(diff, 'og_image', null); // Explicitly add `og_image` as nil if missing in payload
  }

  // Return the object containing only the differences
  return diff;
};

/**
 * Generate card header style based on changed fields.
 * @param {Set<string> | undefined} changedFields - The set of changed fields.
 * @param {string[]} attributes - The list of attributes to check for changes.
 * @param {string} prefix - The prefix to check for specific changes (e.g., 'event_params.' or 'outcome_options.').
 * @param {React.CSSProperties} defaultStyle - The default style to apply if no changes are detected.
 * @param {React.CSSProperties} changedStyle - The style to apply if changes are detected.
 * @returns {React.CSSProperties} - The style object for the card header.
 */
export const getCardHeadStyle = (
  changedFields: Set<string> | undefined,
  attributes: string[],
  prefix: string,
  defaultStyle: React.CSSProperties,
  changedStyle: React.CSSProperties
): React.CSSProperties => {
  const hasChangedFields =
    changedFields &&
    (attributes.some(field => changedFields.has(field)) ||
      (prefix && Array.from(changedFields || []).some(field => field.startsWith(prefix))));

  return hasChangedFields ? changedStyle : defaultStyle;
};

type ErrorCodeKeys = keyof typeof ERROR_CODES;

export const getErrorMessage = (code: ErrorCodeKeys): string =>
  ERROR_CODES[code] || 'Something went wrong';

type ErrorMessageKeys = keyof typeof ERROR_MESSAGES;

export const getFormattedErrorMessage = (message: ErrorMessageKeys): string =>
  ERROR_MESSAGES[message] || 'Something went wrong';

/**
 * Sets the value of the specified key to null if the key does not exist or its value is 0.
 *
 * @param {Record<string, any>} obj - The object to check and modify.
 * @param {string} key - The key to check in the object.
 * @returns {void} - This function does not return a value.
 */
export const setNullIfZero = (obj: Record<string, any>, key: string): void => {
  const value = get(obj, key);
  if (isNil(value) || value === 0) {
    obj[key] = null;
  }
};

/**
 * Generates a formatted string based on the request type and category.
 *
 * @param {string} [request_type] - The type of the request (e.g., "create").
 * @param {string} [request_category] - The category of the request entity (e.g., "Rewards", "Stamps").
 * @returns {string} A formatted string combining `category` and `type`.
 */
export const formatRequestType = (request_type?: string, request_category?: string): string => {
  const normalizedCategory = (request_category || '').toLowerCase();

  const validCategories = new Set(['rewards', 'reward', 'stamps', 'game tries', 'loyalty']);
  // special case
  if (request_type?.toLowerCase() === 'reactivate') {
    return 'Reactivate';
  }

  if (request_type?.toLowerCase() === 'deactivate') {
    return 'Deactivate';
  }

  if (request_type?.toLowerCase() === 'create' && validCategories.has(normalizedCategory)) {
    let category: string;

    switch (normalizedCategory) {
      case 'rewards':
        category = 'Reward';
        break;
      case 'loyalty':
        category = 'Loyalty Points';
        break;
      default:
        category = request_category;
    }

    return `Issue ${category}`;
  }

  return request_category && request_type ? `${request_category} ${request_type}` : 'N.A';
};

/**
 * Checks if the user has the "Tenant Admin" role.
 *
 * @param {Array} roles - An array of role objects, each containing a `name` property.
 * @returns {boolean} - Returns `true` if any role has the name "tenant admin" (case insensitive), otherwise `false`.
 */
export const checkIsRoleTenantAdmin = (roles: Array<any> = []): boolean =>
  roles.some(role => ['tenant admin', 'Tenant Admin'].includes(role.name));

/**
 * Determines if the user is allowed to create based on Maker Checker (MC) status and permissions.
 *
 * @param {boolean} isMCenabled - Indicates if the Maker Checker (MC) feature is enabled.
 * @param {string[]} permissions - An array of permissions assigned to the user.
 * @returns {boolean} - Returns `true` if the user is allowed to create, otherwise `false`.
 *
 * @remarks
 * - If MC is enabled and the user has the 'create' permission, the function returns `true`.
 * - If MC is disabled and the user has the 'edit' permission, the function returns `true`.
 * - In all other cases, the function returns `false`.
 *  - THIS FUNCTION SHOULD ONLY BE USED DURING WITHIN THIS CRITERIA IN CAMPAIGNS ONLY.
 */
export const isCreateAllowed = (isMCenabled: boolean, permissions: string[]): boolean => {
  return isMCenabled ? permissions.includes('create') : permissions.includes('edit');
};

/**
 * Determines if a duplicate action is allowed based on Maker-Checker settings and user permissions.
 *
 * @param isMCenabled - A boolean indicating if Maker-Checker is enabled.
 * @param permissions - An array of strings representing the user's permissions.
 * @param isDuplicate - A boolean indicating if the action is a duplicate.
 * @returns A boolean indicating if the duplicate action is allowed.
 *
 * - THIS FUNCTION SHOULD ONLY BE USED DURING WITHIN THIS CRITERIA IN CAMPAIGNS ONLY.
 */
export const isDuplicateActionAllowed = (
  isMCenabled: boolean,
  permissions: string[],
  isDuplicate: boolean
): boolean => {
  if (!isDuplicate) return false;
  return isCreateAllowed(isMCenabled, permissions);
};
