import {
  CustomerDeactivationModalKey,
  CustomerDetailsKey,
  CustomerListPageKey,
  CustomerListTableKey,
  CustomerPendingListTableKey,
  CustomerUploadModalKey
} from '../../TModules';

export const translations = {
  [CustomerListTableKey]: {
    Identifier: 'Identifiant',
    Name: 'Nom',
    Status: 'Statut',
    DateJoined: "Date d'adhésion",
    Actions: 'Actions',
    SearchCustomers: 'Rechercher des clients',
    Activate: 'Activer',
    Deactivate: 'Désactiver',
    Active: 'Actif',
    Inactive: 'Inactif',
    Initial: 'Initial'
  },
  [CustomerUploadModalKey]: {
    HandleUpload: {
      SuccessMessage: 'Fichier téléchargé',
      ErrorMessage: 'Erreur lors du téléchargement du fichier.',
      Title: 'Télécharger le fichier client',
      OkText: 'Télécharger',
      CancelText: 'Annuler',
      UploadButton: 'Télécharger',
      UploadDraggerMessage:
        'Cliquez ou faites glisser le fichier dans cette zone pour le télécharger',
      DownloadSample: "Télécharger l'exemple",
      AcceptedFormat: 'Format accepté : fichiers .txt., .xlsx ou .csv uniquement'
    }
  },
  [CustomerPendingListTableKey]: {
    RequestId: 'ID de la demande',
    Customer: 'Client',
    RequestType: 'Type de demande',
    Status: 'Statut',
    Comment: 'Commentaire',
    LastUpdated: 'Dernière mise à jour',
    DateOfExpiry: "Date d'expiration",
    Actions: 'Actions',
    Comments: 'Commentaires',
    ViewDetails: 'Voir les détails',
    Approve: 'Approuver',
    ApproveRequests: 'Approuver les demandes',
    CustomerStampView: 'Vue du tampon client',
    LoyaltyProgramDetails: 'Détails du programme de fidélité',
    CustomerGameView: 'Vue du jeu client',
    CustomerRewardView: 'Vue de la récompense client',
    ApproveSelectedButton: 'Approuver la sélection',
    ApproveMultipleMessageModal: {
      SuccessMessage: 'Téléchargements approuvés',
      FailureMessage: "Erreur lors de l'approbation du fichier.",
      Title: 'Approuver plusieurs demandes ?',
      OkText: 'Approuver sélectionné',
      DescriptionText: `
      Êtes-vous sûr de vouloir approuver toutes les demandes sélectionnées ?
      <br />
      Veuillez vérifier les détails avant d'approuver car cette action ne peut pas être annulée.`
    },
    ApproveRequestModal: {
      SuccessMessage: 'Demande approuvée',
      FailureMessage: "Erreur lors de l'approbation de la demande.",
      Title: 'Approuver la demande ?',
      OkText: 'Approuver',
      DescriptionText: `Êtes-vous sûr de vouloir approuver cette demande ?`
    },
    UploadCommentModal: {
      ViewAndAddComments: 'Voir et ajouter des commentaires',
      Content: 'Contenu',
      DeleteButton: 'Bouton supprimer',
      Time: 'Heure',
      ErrorAddingComment: "Erreur lors de l'ajout du commentaire",
      CommentAdded: 'Commentaire ajouté',
      EnterYourCommentHere: 'Entrez votre commentaire ici',
      AddComment: 'Ajouter un commentaire',
      CommentsAdded: 'Commentaires ajoutés',
      Delete: 'Supprimer'
    },
    DeleteCommentModal: {
      CommentDeleted: 'Commentaire supprimé',
      ErrorDeletingComment: 'Erreur lors de la suppression du commentaire',
      DeleteComment: 'Supprimer le commentaire ?',
      Delete: 'Supprimer',
      Description: `
      Êtes-vous sûr de vouloir supprimer votre commentaire ?
      <br />
      Cette action ne peut pas être annulée.
      `
    }
  },
  [CustomerListPageKey]: {
    PendingRequests: 'Demandes en attente',
    Customers: 'Clients',
    CustomersList: 'Liste des clients',
    Upload: 'Télécharger',
    TransactionRequests: 'Demandes de transaction'
  },
  [CustomerDeactivationModalKey]: {
    ProvideReasonForDeactivation: 'Fournir une raison pour la désactivation',
    ProvideReasonForActivation: "Fournir une raison pour l'activation",
    Deactivate: 'Désactiver',
    Activate: 'Activer',
    Cancel: 'Annuler',
    DeactivationReason: 'Veuillez sélectionner la raison'
  },
  [CustomerDetailsKey]: {
    ID: 'ID',
    CustomerDetails: 'Détails du client',
    Identifier: 'Identifiant',
    UserInfo: 'Infos utilisateur',
    FirstName: 'Prénom',
    MiddleName: 'Deuxième prénom',
    LastName: 'Nom de famille',
    Gender: 'Genre',
    Address: 'Adresse',
    Birthday: 'Anniversaire',
    JoinedAt: 'Rejoint le',
    CreatedVia: 'Créé via',
    ReferralCode: 'Code de parrainage',
    Email: 'Email',
    Phone: 'Téléphone',
    State: 'État',
    City: 'Ville',
    Race: 'Race',
    Segment: 'Segment',
    AccountId: 'ID de compte',
    Nationality: 'Nationalité',
    AlternateId: 'ID alternatif',
    ProductType: 'Type de produit',
    Classification: 'Classification',
    LocationState: 'État de localisation',
    PreferredLanguage: 'Langue préférée',
    FindRewardByNameTypeOrMerchant: 'Trouver une récompense par nom, type ou commerçant',
    IssueRewards: 'Émettre des récompenses',
    ErrorRetrievingCustomer: 'Erreur lors de la récupération du client',
    CustomerRewards: 'Récompenses du client',
    LoyaltyProgram: 'Programme de fidélité',
    AccountDetails: 'Détails du compte',
    Campaigns: 'Campagnes',
    CustomerIDNotFound: 'ID client non trouvé',
    DeleteUser: "Supprimer l'utilisateur",
    Activate: 'Activer',
    Deactivate: 'Désactiver',
    ACTIVE: 'ACTIF',
    Inactive: 'Inactif',
    DeactivationReason: 'Raison de la désactivation',

    RewardIssueMakerCheckerErrorMessage: 'Vous ne pouvez pas approuver votre propre demande',
    RewardIssueErrorMessage: "L'erreur s'est produite lors de l'émission de récompenses",
    RewardIssueErrorsOccurredWhileIssuingRewards:
      'Les erreurs se sont produites lors de la délivrance des récompenses',
    RewardIssueSuccessMessage: 'Récompense émise avec succès',
    RewardIssueMakerCheckerSuccessMessage:
      "Demande d'émission de récompense soumise pour approbation",
    RewardIssueUnexpectedResponseFormat: 'Format de réponse inattendu',
    Stamps: 'Timbres',
    Games: 'Jeux',
    ProgressCampaign: 'Campagne de progrès',
    AddressState: "État de l'adresse",
    AddressCity: "S'adresser à la ville",
    Company: 'Entreprise',
    EmailTags: 'Tags e-mail',
    BusinessType: "Type d'entreprise",
    INACTIVE: 'INACTIF',

    Name: 'Nom',
    Status: 'Statut',
    Tenure: 'Durée',
    Tier: 'Niveau',
    Balance: 'Solde',
    ToNextTier: 'Au prochain niveau',
    MembershipExpiry: "Expiration de l'adhésion",

    Rewards: {
      FindRewardByNameTypeOrMerchant: 'Trouver une récompense par nom, type ou commerçant',
      IssueRewardsManually: 'Émettre des récompenses manuellement',
      Issued: 'Émis',
      Pending: 'En attente',
      GiftedAway: 'Offert',
      Redeemed: 'Utilisé',
      RedemptionInProgress: 'Rachat en cours',
      FullyRedeemed: 'Complètement utilisé',
      Voided: 'Annulé',
      Expired: 'Expiré',
      Archived: 'Archivé',

      RequestType: 'Type de demande',
      CancelVoidRequest: "Annuler la demande d'annulation",
      CancelUpdateRequest: 'Annuler la demande de mise à jour',
      CancelIssueRequest: 'Annuler la demande de problème',
      ErrorApprovingRewards: "Erreur lors de l'approbation des récompenses",

      Name: 'Nom',
      DeletionRequested: 'Suppression demandée',
      ID: 'ID',
      Merchant: 'Commerçant',
      DateOfIssued: "Date d'émission",
      ValidFrom: 'Valide à partir de',
      ValidTo: "Valide jusqu'à",
      VoucherCode: 'Code du bon',
      AdminFee: 'Frais administratifs',
      Action: 'Action',
      DateOfExpired: "Date d'expiration",
      DateOfGifted: 'Date de cadeau',
      DateOfRedeemed: 'Date de rachat',
      RequestId: 'ID de la demande',
      Status: 'Statut',
      RewardId: 'ID de la récompense',
      IssuedOn: 'Émis le',

      VoidVoucher: 'Annuler le bon',
      ChangeExpiryDate: "Modifier la date d'expiration",
      AmendReward: 'Modifier la récompense',
      UndoRedeem: 'Annuler le rachat',
      ThereIsNoActionForGiftedVoucher: 'Aucune action pour le bon offert.',
      RevertBackToIssued: 'Revenir à émis',
      WaiveAdminFee: 'Renoncer aux frais administratifs',
      DeleteReward: 'Supprimer la récompense',
      ViewComments: 'Voir les commentaires',
      ApproveIssue: "Approuver l'émission",

      ViewAndAddComments: 'Voir et ajouter des commentaires',
      RequestHasBeenApproved: 'La demande a été approuvée',
      SomethingWentWrong: "Quelque chose s'est mal passé",
      HandleUndoRedeemErrorMessage:
        "Impossible d'annuler le rachat - veuillez modifier la date d'expiration et réessayer.",
      HandleUndoRedeemConfirmModalTitle: 'Confirmer',
      HandleUndoRedeemConfirmModalMessage: "Cela rétablira l'état du bon à Émis - continuer ?",

      HandleVoidVoucherMakerCheckerSuccessMessage: "Demande d'annulation soumise pour approbation",
      HandleVoidVoucherSuccessMessage: 'Le bon est annulé',
      HandleVoidVoucherConfirmModalMakerCheckerRightButtonText: "Demander l'annulation",
      HandleVoidVoucherConfirmModalRightButtonText: 'Confirmer',
      HandleVoidVoucherConfirmModalMakerCheckerTitle: "Soumettre la demande d'annulation ?",
      HandleVoidVoucherConfirmModalTitle: 'Confirmer',
      HandleVoidVoucherConfirmModalMakerCheckerMessage:
        "Êtes-vous sûr de vouloir demander l'annulation du bon de récompense ? Une fois approuvé, vous ne pourrez pas le restaurer.",
      HandleVoidVoucherConfirmModalMessage: 'Cela annulera le bon de récompense - continuer ?',

      HandleRevertRedemptionToIssuedConfirmModalTitle: 'Confirmer',
      HandleRevertRedemptionToIssuedMessageConfirmModalMessage:
        "Cela rétablira l'état du bon à Émis - continuer ?",

      ShowOCBCDemoConfirmModalTitle: 'Confirmer',
      ShowOCBCDemoConfirmModalMessage: 'Cela renoncera aux frais administratifs - continuer ?'
    },

    IssueRewardsModal: {
      Title: 'Émettre des récompenses manuellement',
      OkText_one: 'Émettre {{count}} récompense',
      OkText_other: 'Émettre {{count}} récompenses',
      SearchPlaceholder: 'Trouver une récompense par nom, type ou commerçant',
      ActiveTabLabel: 'Actif',
      PrivateTabLabel: 'Privé',
      CancelText: 'Annuler',

      CustomerRewardsConfirmationModal: {
        RewardName: 'Nom de la récompense',
        RewardPrices: 'Récompenser les prix',
        FreeOrPaid: 'Gratuit/Payant',
        ConfirmSellPrice: 'Confirmer le prix de vente',
        And: 'et',
        Points: 'points',
        Or: 'OU',
        Paid: 'Payé',
        Free: 'Gratuit',
        Cancel: 'Annuler',
        IssueRewards: 'ÉMETTRE DES RÉCOMPENSES'
      }
    },
    IssueRewardsTable: {
      Name: 'Nom',
      Merchant: 'Commerçant',
      ValidityPeriod: 'Période de validité',
      Balance: 'Solde'
    },
    AuditLog: {
      Title: "Journal d'audit",
      LastUpdatedAt: 'Dernière mise à jour à',
      IpAddress: 'Adresse IP',
      User: 'Utilisateur',
      Action: 'Action',
      Description: 'Description',
      RenderSummaryIsCreated: 'créé',
      RenderSummaryIsUpdated: 'mis à jour'
    },
    DeleteCustomerModal: {
      Title: "Supprimer l'utilisateur ?",
      OkText: 'confirmer',
      CancelText: 'Retourner',
      DescriptionLineOne: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
      DescriptionLineTwo: 'Cette action ne peut pas être annulée.',
      Name: 'Nom :',
      Identifier: 'Identifiant :',
      MakesCheckerErrorMessage:
        "Implémentez l'intégration de l'API avec Maker Checker en cours de progrès"
    },

    LoyaltyProgramsTable: {
      Name: 'Nom',
      Status: 'Statut',
      Tenure: 'Durée',
      Tier: 'Niveau',
      Balance: 'Solde',
      ToNextTier: 'Au prochain niveau',
      MembershipExpiry: "Expiration de l'adhésion",
      Actions: 'Actions'
    },
    LoyaltyProgramDetailsModal: {
      Title: 'Détails du programme de fidélité',
      CloseButton: 'FERMER',
      Active: 'Actif',
      Pending: 'En attente',
      Actions: 'Actions',
      ActionCancelRequest: 'Annuler la demande',
      ActionApproveIssue: "Approuver l'émission",
      ActionApproveDeletion: 'Approuver la suppression',
      ActionDenyDeletion: 'Refuser la suppression',
      ActionComments: 'Commentaires',
      ActionCommentsModalTitle: 'Voir et ajouter des commentaires',

      CancelIssuance: "Annuler l'émission",
      CancelDeletion: 'Annuler la suppression',

      Event: 'Événement',
      EventButton: 'Suppression demandée',
      Status: 'Statut',
      SubmissionDate: 'Date de soumission',
      Points: 'Points',
      ExpiresAt: 'Expire le',
      TransactionId: 'ID de transaction',
      AmountTransacted: 'Montant transigé',
      TransactionDate: 'Date de transaction',
      TransactionRef: 'Réf. de transaction',
      Merchant: 'Commerçant',
      GlAccountNumber: 'Numéro de compte GL',
      CostCenterId: 'ID centre de coût',
      BranchCode: 'Code de la succursale',

      ChangeStatus: 'Changer le statut',
      ChangeTier: 'Changer le niveau',
      AddOrRemovePoints: 'Ajouter / Supprimer des points',

      ChangeStatusForm: {
        Active: 'Actif',
        Inactive: 'Inactif',
        Save: 'Sauvegarder'
      },
      ChangeTierForm: {
        Label: 'Remplacer le niveau expire le:',
        Save: 'Sauvegarder'
      },
      PointsForm: {
        SelectGlAccount: 'Sélectionner le compte GL',
        GlAccountType: 'Type de compte GL',
        GlAccountName: 'Nom du compte GL',
        Message: 'Écrivez un message pour accompagner cet ajustement de points.',
        AdjustPointsButton: 'Ajuster les points'
      },
      UpdateLoyaltyProgramCustomerStateSuccessMessage: 'État mis à jour',
      UpdateLoyaltyProgramCustomerStateErrorMessage: 'quelque chose a mal tourné',
      UpdateLoyaltyProgramCustomerTierSuccessMessage: 'Niveau mis à jour',
      UpdateLoyaltyProgramCustomerTierErrorMessage: 'quelque chose a mal tourné',
      CreateCustomerLoyaltyTransaction: {
        GLAccountTypeRequiredMessage: 'Le type de compte GL est requis',
        GlAccountNameRequiredMessage: 'Le nom du compte GL est requis',
        MakerCheckerSuccessMessage: "Demande d'émission de points soumise pour approbation",
        SuccessMessage: 'Transaction ajoutée',
        ErrorMessage: 'quelque chose a mal tourné'
      },
      DeleteCustomerRequestLoyaltyTransaction: {
        SuccessMessage: 'Demande annulée',
        ErrorMessage: 'quelque chose a mal tourné'
      },
      ApproveDenyCustomerRequestLoyaltyTransaction: {
        ApproveSuccessMessage: 'La demande a été ',
        ErrorMessage: 'quelque chose a mal tourné',
        RequestHasBeenDeletedMessage: 'La demande a été supprimée',
        RequestHasBeenApprovedMessage: 'La demande a été approuvée'
      },
      DeleteCustomerLoyaltyTransaction: {
        MakerCheckerSuccessMessage:
          'Demande de suppression de transaction soumise pour approbation',
        SuccessMessage: 'Transaction supprimée',
        ErrorMessage: 'quelque chose a mal tourné'
      },
      RecoverCustomerLoyaltyTransaction: {
        SuccessMessage: 'Transaction récupérée',
        ErrorMessage: 'quelque chose a mal tourné'
      },
      DeleteCustomerLoyaltyTransactionModal: {
        ActiveTitle: 'Soumettre la demande de suppression ?',
        ActiveOkText: 'Demander la suppression',
        ActiveCancelText: 'Annuler',
        CancelText: 'Retour',
        ActiveMessage:
          'Êtes-vous sûr de vouloir demander la suppression de ces points ? Une fois approuvé, vous ne pourrez pas les restaurer.',
        MessageLineOne: `L'émission de ces points est en attente d'approbation. Êtes-vous sûr de vouloir annuler la demande ?`,
        MessageLineTwo: 'Cette action ne peut pas être annulée.',

        CancelDeletionTitle: 'Annuler la demande de suppression des points?',
        Title: "Annuler la demande d'émission de points ?",
        OkText: 'Confirmer',
        MessageLineOneDeletion: "La suppression de ces points est en attente d'approbation. "
      },
      ApproveDenyModal: {
        DenyDeletionOkText: 'Refuser',
        OkText: 'Approuver',
        ApproveIssueTitle: 'Approuver le problème des points de fidélité',
        ApproveDeletionTitle: 'Approuver la suppression des points de fidélité',
        DenyDeletionTitle: 'Refuser la demande de suppression de points de fidélité',
        ApproveIssueAndDeletionMessageLineOne: 'Êtes-vous sûr de vouloir approuver cela',
        ApproveIssueAndDeletionMessageLineTwo: 'de points de fidélité ? ',
        Message: 'Êtes-vous sûr de vouloir nier la suppression de ces points de fidélité? ',
        Issuance: 'émission',
        Deletion: 'effacement'
      },

      LineProgressBar: {
        HeaderDateText: 'début de période',
        CustomerTierText: "L'utilisateur est actuellement dans",
        Tier: 'Niveau',
        CustomerForcedMinimumTierText: "L'utilisateur a été forcé dans le niveau minimum",
        CustomerForcedMaximumTierText: `L'utilisateur a été `,
        FORCED: 'FORCÉ',
        into: 'dans',
        EndOfPeriod: 'fin de période',
        ForcedTier: 'Niveau forcé',
        ForcedMinTier: 'Niveau minimum forcé',
        UserHasBeen: "L'utilisateur a été",
        Forced: 'forcé',
        To: 'à',
        Status: 'statut',
        Until: "jusqu'à",
        DeleteForcedTierLinkText: 'X Désactiver le niveau forcé',
        ToMinimum: 'au minimum',
        TheUserHasEarned: "L'utilisateur a gagné",
        Points: 'Point(s)',
        DuringThisCycle: 'pendant ce cycle.',
        TheUserRequires: "L'utilisateur nécessite",
        ToLevelUpTo: 'pour passer au niveau supérieur',
        TheUserHas: "L'utilisateur a",
        Available: 'disponible.'
      }
    },

    CustomerCampaigns: {
      ID: 'ID',
      HitThePinata: 'Frappez la pinata',
      ScratchCard: 'Carte à gratter',
      Plinko: 'Plinko',
      Name: 'Nom',
      CampaignType: 'Type de campagne',
      Status: 'Statut',
      StartDate: 'Date de début',
      EndDate: 'Date de fin',
      Actions: 'Actions',
      SearchCampaignsPlaceholder: 'Rechercher des campagnes',
      UnenrollCustomer: 'Désinscrire le client',
      NotStarted: 'Pas commencé',
      Active: 'Actif',
      Completed: 'Terminé',
      NotCompleted: 'Non terminé',
      Enrollable: 'Inscription possible',
      EndedAndNotEnrolled: 'Terminé et non inscrit',
      Enrolled: 'Inscrit',
      NotEnrolled: 'Non inscrit',
      SpinTheWheel: 'Tournez la roue',
      ShakeTheTree: "Secouez l'arbre",
      EnrollCustomer: 'Inscrire le client',
      SelectCampaignsPlaceholder: 'Sélectionner les campagnes',

      SelectedTypeCampaigns: {
        Customers: 'Clients',
        Campaigns: 'Campagnes',
        StampCampaign: 'Campagne de timbres',
        QuizCampaign: 'Campagne de quiz',
        SurveyCampaign: 'Campagne de sondages',
        GameCampaign: 'Campagne de jeux',
        ProgressCampaign: 'Campagne de progression',
        QuestCampaign: 'Campagne de quête',
        InstantRewardCampaign: 'Campagne de récompense instantanée',
        CampaignProgress: 'Ce client est actuellement sur'
      },

      RaffleView: {
        TriggerName: 'Nom du déclencheur',
        RaffleRuleID: 'ID de la règle de tombola',
        TransactionDetails: 'Détails de la transaction',
        TransactionRuleID: 'ID de la règle de transaction',
        TriggeredDateOrTime: 'Date / Heure déclenchée',
        RaffleCode: 'Code de tombola',
        State: 'État'
      },

      InstantRewardView: {
        TriggerName: 'Nom du déclencheur',
        RuleID: 'ID de la règle',
        TransactionDetails: 'Détails de la transaction',
        TriggeredDateOrTime: 'Date / Heure déclenchée'
      },

      QuestView: {
        TriggerName: 'Nom du déclencheur',
        Task: 'Tâche',
        QuestRuleID: 'ID de la règle de quête',
        TransactionDetails: 'Détails de la transaction',
        TransactionRuleID: 'ID de la règle de transaction',
        TriggeredDateOrTime: 'Date / Heure déclenchée',
        State: 'État',
        Actions: 'Actions',
        MarkCompleted: 'Marquer comme terminé',
        MarkCompletedSuccessMessage: 'Points mis à jour avec succès!',
        MarkCompletedErrorMessage: 'Erreur de mise à jour des points'
      },

      CustomerProgressCard: {
        TriggerName: 'Nom du déclencheur',
        ProgressRuleID: 'ID de la règle de progression',
        TransactionDetails: 'Détails de la transaction',
        TransactionID: 'ID de transaction',
        TriggeredDateOrTime: 'Date / Heure déclenchée',
        Points: 'Points'
      },

      CustomerGameView: {
        TriesID: 'ID des essais',
        Status: 'Statut',
        AcquiredVia: 'Acquis via',
        ReasonCode: 'Code de raison',
        Email: 'Email',
        ID: 'ID',
        DateOfIssue: "Date d'émission",
        DateOfExpiry: "Date d'expiration",
        Outcomes: 'Résultats',
        VoucherID: 'ID du bon',
        NoOutcome: 'pas de résultat',
        SorrySomethingWentWrong: "Désolé, quelque chose s'est mal passé!",
        All: 'Tous',
        Issued: 'Émis',
        Redeem: 'Racheter',
        Expired: 'Expiré',
        Pending: 'En attente',
        PendingColumns: {
          RequestID: "Demande d'identité",
          Status: 'Statut',
          Reason: 'Raison',
          NumberOfTries: "Nombre d'essais",
          DateOfRequest: 'Date de demande',
          Actions: 'Actes'
        },
        ApproveIssue: 'Approuver le problème',
        ViewComments: 'Afficher les commentaires',
        ViewAndAddComments: 'Afficher et ajouter des commentaires',
        ApproveIssueSuccessMessage: 'La demande a été approuvée',
        ApproveIssueErrorMessage: "Quelque chose s'est mal passé",
        ApproveDenyModal: {
          Title: 'Approuver le problème des récompenses',
          OkText: 'Approuver',
          CancelText: 'Annuler',
          Description: "Êtes-vous sûr de vouloir approuver l'émission de cette récompense ? "
        },
        CustomerCardView: {
          Slot: 'Fente #',
          StampId: "Carte d'identité",
          Status: 'Statut',
          AcquiredVia: 'Acquis via',
          StampIssuedDate: "Date d'émission du timbre",
          StampRedeemedDate: "Date d'échange du timbre",
          Outcomes: 'Résultats',
          RedeemTheStamp: 'Échanger le timbre',
          EmptySlots: 'Emplacements vides',
          SlotEmpty: 'Emplacement vide',
          ID: 'IDENTIFIANT',
          VoucherID: 'Identifiant de bon',
          RewardID: 'Identifiant de récompense',
          NoRewards: 'Aucune récompense émise',
          NoRewardsIssued: 'Aucune récompense émise',
          Note: 'Note',
          Email: 'E-mail',
          SorrySomethingWentWrong: "Désolé, quelque chose s'est mal passé"
        }
      },

      CustomerQuizAndSurveyView: {
        QuestionNo: 'Question n°',
        Question: 'Question',
        Response: 'Réponse',
        SubmissionDateOrTime: 'Date / Heure de soumission',
        Result: 'Résultat'
      },

      IssueGameChangeModal: {
        MakerCheckerSuccessMessage: "Demande d'émission de chance de jeu soumise pour approbation",
        SuccessMessage: 'Chances de jeu émises avec succès!',
        ErrorMessage: "Erreur lors de l'émission des chances de jeu",
        IssueGameTriesButton: 'Émettre des essais de jeu',
        Title: 'Émettre une chance de jeu à',
        OkText: 'Émettre une chance',
        HowManyChances: 'Combien de chances?',

        IssueGameChanceButton: 'Problème de chance de jeu',
        ReasonForIssuance: "Raison de l'émission",
        ChanceCountValidationMessage: 'Veuillez spécifier un nombre de chances.',
        ReasonForIssuanceValidationMessage: "Veuillez spécifier une raison de l'émission."
      },

      CustomerStampCards: {
        CardHash: 'Carte #',
        StampCardID: 'ID de la carte de timbre',
        State: 'État',
        ExpiryDate: "Date d'expiration",
        DateOfParticipation: 'Date de participation',
        StampsCollected: 'Timbres collectés',
        CompletedDate: 'Date de fin',
        RewardsAchieved: 'Récompenses obtenues',

        PendingColumns: {
          RequestID: 'Numéro de demande',
          State: 'État',
          Reason: 'Raison',
          NumberOfStamps: 'Nombre de timbres',
          DateOfRequest: 'Date de demande',
          Actions: 'Actes'
        },
        CancelIssuance: "Annuler l'émission",
        ApproveIssue: 'Approuver le problème',
        ApproveIssueSuccessMessage: 'La demande a été approuvée',
        ApproveIssueErrorMessage: "Quelque chose s'est mal passé",
        ViewComments: 'Afficher les commentaires',
        Active: 'Actif',
        Completed: 'Complété',
        Expired: 'Expiré',
        Pending: 'En attente',
        SorrySomethingWentWrong: "Désolé, quelque chose s'est mal passé"
      },

      IssueStampsModal: {
        MakerCheckerSuccessMessage: "Demande d'émission de timbres soumise pour approbation",
        SuccessMessage: 'Émission de timbres réussie!',
        IssueStampsButton: 'Émettre des timbres',
        Title: 'Émettre des timbres à',
        OkText: 'Émettre des timbres',
        HowManyStamps: 'Combien de timbres?',

        ErrorMessage: "Erreur lors de l'émission des timbres",
        CancelText: 'Annuler',
        ReasonForIssuance: "Raison de l'émission",
        CustomerFullNameDefault: 'Client actuel',
        StampsCountValidationMessage: 'Veuillez préciser le nombre de timbres.',
        ReasonToIssuanceValidationMessage: "Veuillez préciser le motif de l'émission"
      },

      DeleteRequestModal: {
        SuccessMessage: 'La demande a été annulée',
        ErrorMessage: "Quelque chose s'est mal passé",
        Title: "Annuler {{type}} Demande d'émission?",
        OkText: 'Confirmer',
        CancelText: 'Annuler',
        DescriptionLineOne: "Ce {{lowerCaseType}} est en attente d'approbation. ",
        DescriptionLineTwo:
          "Une fois l'annulation approuvée, cela {{lowerCaseType}} ne sera pas disponible et vous devrez le créer à nouveau."
      },

      IssueRewardModal: {
        RewardIssuedSuccessfully: 'Récompense émise avec succès!',
        ErrorIssueReward: "Erreur lors de l'émission de la récompense",
        IssueRewardTitle: 'Émettre des récompenses',
        IssueRewardOkText: 'Émettre une récompense',
        IssueRewardButton: 'Émettre une récompense',
        ReasonForAdjustment: "Raison de l'ajustement"
      },

      AddRemovePointsModal: {
        PointsUpdatedSuccessfully: 'Points mis à jour avec succès!',
        ErrorUpdatingPoints: 'Erreur lors de la mise à jour des points',
        AddOrRemovePointsButton: 'Ajouter/Retirer des points',
        AdjustProgressCampaignPoints: 'Ajuster les points de la campagne de progression',
        OkText: 'Ajuster les points',
        HowManyPointsToAddOrRemove: 'Combien de points ajouter/retirer?',
        ReasonForAdjustment: "Raison de l'ajustement"
      }
    },

    CustomerStamps: {
      All: 'Tous',
      Active: 'Actif',
      Inactive: 'Inactif',
      Name: 'Nom',
      Status: 'Statut',
      Started: 'Commencé',
      Ended: 'Terminé',
      ID: 'IDENTIFIANT',
      SearchPlaceholder: 'Rechercher une campagne par son nom',
      EnrollCampaignModal: {
        SuccessMessage: 'Campagne inscrite réussie!',
        ErrorMessage: 'La campagne inscrite a échoué.',
        CampaignIDValidationMessage: 'Veuillez spécifier une campagne',
        FullNameDefault: 'Client actuel',
        ManualEnrollButton: 'Inscription manuelle',
        Title: 'Inscrire',
        OkText: 'Inscrire le client',
        CancelText: 'Annuler'
      },
      CampaignSelectField: {
        Label: 'Sélectionner la campagne',
        Edit: 'Modifier',
        Add: 'Ajouter',
        Campaign: 'Campagne'
      },

      CampaignSelectModal: {
        Title: 'Sélectionnez une campagne',
        CancelText: 'Annuler',
        OkText: 'Ajouter une campagne',
        SearchPlaceholder: 'Trouvez une campagne par nom, type ou marchand',
        Name: 'Nom',
        ID: 'IDENTIFIANT',
        Status: 'Statut',
        Tags: 'Balises',
        StartDate: 'Date de début',
        EndDate: 'Date de fin'
      }
    },

    CustomerGames: {
      Name: 'Nom',
      ID: 'IDENTIFIANT',
      Status: 'Statut',
      GameType: 'Type de jeu',
      TriesRedeemed: 'Essais échangés',
      Started: 'Commencé',
      Ended: 'Terminé',
      SearchPlaceholder: 'Trouvez une campagne par son nom',
      SorrySomethingWentWrong: "Désolé, quelque chose s'est mal passé",
      Tabs: {
        All: 'Tous',
        Active: 'Actif',
        Approved: 'Approuvé',
        Inactive: 'Inactif',
        Ended: 'Terminé'
      }
    }
  }
};
