import { useFeatureFlags, usePermissions } from 'hooks/index';

/**
 * Determines the maker-checker permissions and feature enablement for a given feature and permission key.
 *
 * @param {string} featureKey - The key representing the feature flag to check.
 * @param {string} permissionKey - The key representing the permissions to evaluate.
 * @returns {{
 *   enabled: boolean,
 *   canView: boolean,
 *   canEdit: boolean,
 *   canCreate: boolean,
 *   canEditOrCreate: boolean,
 *   canApprove: boolean,
 * }} - An object indicating feature enablement and specific role permissions.
 */
const useMakerCheckerPermissions = (
  featureKey: string,
  permissionKey: string
): {
  enabled: boolean;
  canView: boolean;
  canEdit: boolean;
  canCreate: boolean;
  canEditOrCreate: boolean;
  canApprove: boolean;
} => {
  const featureFlags = useFeatureFlags();
  const permissions = usePermissions(permissionKey);

  const enabled = !!featureFlags[featureKey];
  const actions = permissions.actions || [];

  const canView = actions.includes('view');
  const canApprove = canView && actions.includes('approve');
  const canEdit = canView && actions.includes('edit');
  const canCreate = canView && actions.includes('create');
  const canEditOrCreate = canEdit || canCreate;

  return {
    enabled,
    canView,
    canEdit,
    canCreate,
    canEditOrCreate,
    canApprove
  };
};

export default useMakerCheckerPermissions;
