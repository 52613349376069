import {
  CustomerDeactivationModalKey,
  CustomerDetailsKey,
  CustomerListPageKey,
  CustomerListTableKey,
  CustomerPendingListTableKey,
  CustomerUploadModalKey
} from '../../TModules';

export const translations = {
  [CustomerListTableKey]: {
    Identifier: 'Identificatore',
    Name: 'Nome',
    Status: 'Stato',
    DateJoined: 'Data di adesione',
    Actions: 'Azioni',
    SearchCustomers: 'Cerca clienti',
    Activate: 'Attiva',
    Deactivate: 'Disattiva',
    Active: 'Attivo',
    Inactive: 'Inattivo',
    Initial: 'Iniziale'
  },
  [CustomerUploadModalKey]: {
    HandleUpload: {
      SuccessMessage: 'File caricato',
      ErrorMessage: 'Errore durante il caricamento del file.',
      Title: 'Carica file cliente',
      OkText: 'Carica',
      CancelText: 'Annulla',
      UploadButton: 'Carica',
      UploadDraggerMessage: "Fare clic o trascinare il file in quest'area per caricarlo",
      DownloadSample: 'Scarica esempio',
      AcceptedFormat: 'Formato accettato: solo file .txt., .xlsx o .csv'
    }
  },
  [CustomerPendingListTableKey]: {
    RequestId: 'ID richiesta',
    Customer: 'Cliente',
    RequestType: 'Tipo di richiesta',
    Status: 'Stato',
    Comment: 'Commento',
    LastUpdated: 'Ultimo aggiornamento',
    DateOfExpiry: 'Data di scadenza',
    Actions: 'Azioni',
    Comments: 'Commenti',
    ViewDetails: 'Visualizza dettagli',
    Approve: 'Approva',
    ApproveRequests: 'Approva richieste',
    CustomerStampView: 'Vista timbro cliente',
    LoyaltyProgramDetails: 'Dettagli programma fedeltà',
    CustomerGameView: 'Vista gioco cliente',
    CustomerRewardView: 'Vista ricompensa cliente',
    ApproveSelectedButton: 'Approva selezionati',
    ApproveMultipleMessageModal: {
      SuccessMessage: 'Upload approvati',
      FailureMessage: 'Errore nell’approvazione del file.',
      Title: 'Approvare più richieste?',
      OkText: 'Approva selezionati',
      DescriptionText: `
      Sei sicuro di voler approvare tutte le richieste selezionate?
      <br />
      Verifica i dettagli prima di approvare poiché questa azione non può essere annullata.`
    },
    ApproveRequestModal: {
      SuccessMessage: 'Richiesta approvata',
      FailureMessage: `Errore nell'approvazione della richiesta.`,
      Title: 'Approvare la richiesta?',
      OkText: 'Approva',
      DescriptionText: `Sei sicuro di voler approvare questa richiesta?`
    },
    UploadCommentModal: {
      ViewAndAddComments: 'Visualizza e aggiungi commenti',
      Content: 'Contenuto',
      DeleteButton: 'Pulsante elimina',
      Time: 'Tempo',
      ErrorAddingComment: 'Errore nell’aggiunta del commento',
      CommentAdded: 'Commento aggiunto',
      EnterYourCommentHere: 'Inserisci qui il tuo commento',
      AddComment: 'Aggiungi commento',
      CommentsAdded: 'Commenti aggiunti',
      Delete: 'Elimina'
    },
    DeleteCommentModal: {
      CommentDeleted: 'Commento eliminato',
      ErrorDeletingComment: 'Errore nell’eliminazione del commento',
      DeleteComment: 'Eliminare il commento?',
      Delete: 'Elimina',
      Description: `
      Sei sicuro di voler eliminare il tuo commento?
      <br />
      Questa azione non può essere annullata.
      `
    }
  },
  [CustomerListPageKey]: {
    PendingRequests: 'Richieste in sospeso',
    Customers: 'Clienti',
    CustomersList: 'Elenco clienti',
    Upload: 'Carica',
    TransactionRequests: 'Richieste di transazione'
  },
  [CustomerDeactivationModalKey]: {
    ProvideReasonForDeactivation: 'Fornisci motivo per la disattivazione',
    ProvideReasonForActivation: 'Fornisci motivo per l’attivazione',
    Deactivate: 'Disattiva',
    Activate: 'Attiva',
    Cancel: 'Annulla',
    DeactivationReason: 'Seleziona il motivo'
  },
  [CustomerDetailsKey]: {
    ID: 'ID',
    CustomerDetails: 'Dettagli cliente',
    Identifier: 'Identificatore',
    UserInfo: 'Informazioni utente',
    FirstName: 'Nome',
    MiddleName: 'Secondo nome',
    LastName: 'Cognome',
    Gender: 'Genere',
    Address: 'Indirizzo',
    Birthday: 'Compleanno',
    JoinedAt: 'Iscritto il',
    CreatedVia: 'Creato tramite',
    ReferralCode: 'Codice di riferimento',
    Email: 'Email',
    Phone: 'Telefono',
    State: 'Stato',
    City: 'Città',
    Race: 'Razza',
    Segment: 'Segmento',
    AccountId: 'ID account',
    Nationality: 'Nazionalità',
    AlternateId: 'ID alternativo',
    ProductType: 'Tipo di prodotto',
    Classification: 'Classificazione',
    LocationState: 'Stato della posizione',
    PreferredLanguage: 'Lingua preferita',
    FindRewardByNameTypeOrMerchant: 'Trova ricompensa per nome, tipo o commerciante',
    IssueRewards: 'Emetti ricompense',
    ErrorRetrievingCustomer: 'Errore nel recupero del cliente',
    CustomerRewards: 'Ricompense cliente',
    LoyaltyProgram: 'Programma fedeltà',
    AccountDetails: 'Dettagli account',
    Campaigns: 'Campagne',
    CustomerIDNotFound: 'ID cliente non trovato',
    DeleteUser: 'Elimina utente',
    Activate: 'Attiva',
    Deactivate: 'Disattiva',
    ACTIVE: 'ATTIVO',
    Inactive: 'Inattivo',
    DeactivationReason: 'Motivo della disattivazione',

    RewardIssueMakerCheckerErrorMessage: 'Non puoi approvare la tua richiesta',
    RewardIssueErrorMessage: "Si è verificato un errore durante l'assegnazione dei premi",
    RewardIssueErrorsOccurredWhileIssuingRewards:
      "Si sono verificati errori durante l'emissione di premi",
    RewardIssueSuccessMessage: 'Premio emesso con successo',
    RewardIssueMakerCheckerSuccessMessage:
      "Richiesta di emissione del premio inviata per l'approvazione",
    RewardIssueUnexpectedResponseFormat: 'Formato di risposta inaspettato',
    Stamps: 'Francobolli',
    Games: 'Giochi',
    ProgressCampaign: 'Campagna sul progresso',
    AddressState: "Stato dell'indirizzo",
    AddressCity: 'Affronta la città',
    Company: 'Azienda',
    EmailTags: 'Tag e -mail',
    BusinessType: 'Tipo di attività',
    INACTIVE: 'INATTIVO',

    Name: 'Nome',
    Status: 'Stato',
    Tenure: 'Durata',
    Tier: 'Livello',
    Balance: 'Saldo',
    ToNextTier: 'Al prossimo livello',
    MembershipExpiry: 'Scadenza dell’iscrizione',

    Rewards: {
      FindRewardByNameTypeOrMerchant: 'Trova ricompensa per nome, tipo o commerciante',
      IssueRewardsManually: 'Emetti ricompense manualmente',
      Issued: 'Emesso',
      Pending: 'In sospeso',
      GiftedAway: 'Regalato',
      Redeemed: 'Riscattato',
      RedemptionInProgress: 'Riscatto in corso',
      FullyRedeemed: 'Completamente riscattato',
      Voided: 'Annullato',
      Expired: 'Scaduto',
      Archived: 'Archiviato',

      Name: 'Nome',
      DeletionRequested: 'Eliminazione richiesta',
      ID: 'ID',
      Merchant: 'Commerciante',
      DateOfIssued: 'Data di emissione',
      ValidFrom: 'Valido dal',
      ValidTo: 'Valido fino al',
      VoucherCode: 'Codice voucher',
      AdminFee: 'Commissione amministrativa',
      Action: 'Azione',
      DateOfExpired: 'Data di scadenza',
      DateOfGifted: 'Data di regalo',
      DateOfRedeemed: 'Data di riscatto',
      RequestId: 'ID richiesta',
      Status: 'Stato',
      RewardId: 'ID ricompensa',
      IssuedOn: 'Emesso il',

      RequestType: 'Tipo di richiesta',
      CancelVoidRequest: 'Annulla la richiesta vuota',
      CancelUpdateRequest: 'Annulla la richiesta di aggiornamento',
      CancelIssueRequest: 'Annulla richiesta di emissione',
      ErrorApprovingRewards: 'Si è verificato un errore durante l’approvazione delle ricompense',

      VoidVoucher: 'Annulla voucher',
      ChangeExpiryDate: 'Modifica data di scadenza',
      AmendReward: 'Modifica ricompensa',
      UndoRedeem: 'Annulla riscatto',
      ThereIsNoActionForGiftedVoucher: 'Nessuna azione per voucher regalato.',
      RevertBackToIssued: 'Torna a emesso',
      WaiveAdminFee: 'Elimina commissione amministrativa',
      DeleteReward: 'Elimina ricompensa',
      ViewComments: 'Visualizza commenti',
      ApproveIssue: 'Approva emissione',

      ViewAndAddComments: 'Visualizza e aggiungi commenti',
      RequestHasBeenApproved: 'Richiesta approvata',
      SomethingWentWrong: 'Qualcosa è andato storto',
      HandleUndoRedeemErrorMessage:
        'Non è possibile annullare il riscatto - modifica la data di scadenza e riprova.',
      HandleUndoRedeemConfirmModalTitle: 'Conferma',
      HandleUndoRedeemConfirmModalMessage:
        'Questo riporterà lo stato del voucher a Emesso - continuare?',

      HandleVoidVoucherMakerCheckerSuccessMessage:
        'Richiesta di annullamento inviata per approvazione',
      HandleVoidVoucherSuccessMessage: 'Voucher annullato',
      HandleVoidVoucherConfirmModalMakerCheckerRightButtonText: 'Richiedi annullamento',
      HandleVoidVoucherConfirmModalRightButtonText: 'Conferma',
      HandleVoidVoucherConfirmModalMakerCheckerTitle: 'Inviare richiesta di annullamento?',
      HandleVoidVoucherConfirmModalTitle: 'Conferma',
      HandleVoidVoucherConfirmModalMakerCheckerMessage:
        'Sei sicuro di voler richiedere l’annullamento del voucher premio? Una volta approvato, non potrai ripristinarlo.',
      HandleVoidVoucherConfirmModalMessage: 'Questo annullerà il voucher premio - continuare?',

      HandleRevertRedemptionToIssuedConfirmModalTitle: 'Conferma',
      HandleRevertRedemptionToIssuedMessageConfirmModalMessage:
        'Questo riporterà lo stato del voucher a Emesso - continuare?',

      ShowOCBCDemoConfirmModalTitle: 'Conferma',
      ShowOCBCDemoConfirmModalMessage:
        'Questo eliminerà la commissione amministrativa - continuare?'
    },

    IssueRewardsModal: {
      Title: 'Emetti ricompense manualmente',
      OkText_one: 'Emetti {{count}} ricompensa',
      OkText_other: 'Emetti {{count}} ricompense',
      SearchPlaceholder: 'Trova ricompensa per nome, tipo o commerciante',
      ActiveTabLabel: 'Attivo',
      PrivateTabLabel: 'Privato',
      CancelText: 'Cancellare',

      CustomerRewardsConfirmationModal: {
        RewardName: 'Nome Ricompensa',
        RewardPrices: 'Prezzi Ricompensa',
        FreeOrPaid: 'Gratuito/A Pagamento',
        ConfirmSellPrice: 'Conferma Prezzo di Vendita',
        And: 'e',
        Points: 'punti',
        Or: 'O',
        Paid: 'A Pagamento',
        Free: 'Gratuito',
        Cancel: 'Annulla',
        IssueRewards: 'EMETTI RICOMPENSE'
      }
    },
    IssueRewardsTable: {
      Name: 'Nome',
      Merchant: 'Commerciante',
      ValidityPeriod: 'Periodo di validità',
      Balance: 'Saldo'
    },
    AuditLog: {
      Title: 'Registro di controllo',
      LastUpdatedAt: 'Ultimo aggiornamento alle',
      IpAddress: 'Indirizzo IP',
      User: 'Utente',
      Action: 'Azione',
      Description: 'Descrizione',
      RenderSummaryIsCreated: 'creato',
      RenderSummaryIsUpdated: 'aggiornato'
    },
    DeleteCustomerModal: {
      MakesCheckerErrorMessage: "Implementa l'integrazione dell'API con il Checker Maker in corso",
      Title: 'Eliminare utente?',
      OkText: 'conferma',
      CancelText: 'Torna indietro',
      DescriptionLineOne: 'Sei sicuro di voler eliminare questo utente?',
      DescriptionLineTwo: 'Questa azione non può essere annullata.',
      Name: 'Nome:',
      Identifier: 'Identificatore:'
    },

    LoyaltyProgramsTable: {
      Name: 'Nome',
      Status: 'Stato',
      Tenure: 'Durata',
      Tier: 'Livello',
      Balance: 'Saldo',
      ToNextTier: 'Al prossimo livello',
      MembershipExpiry: 'Scadenza dell’iscrizione',
      Actions: 'Azioni'
    },
    LoyaltyProgramDetailsModal: {
      Title: 'Dettagli programma fedeltà',
      CloseButton: 'CHIUDI',
      Active: 'Attivo',
      Pending: 'In sospeso',
      Actions: 'Azioni',
      ActionCancelRequest: 'Annulla richiesta',
      ActionApproveIssue: 'Approva emissione',
      ActionApproveDeletion: 'Approva eliminazione',
      ActionDenyDeletion: 'Nega eliminazione',
      ActionComments: 'Commenti',
      ActionCommentsModalTitle: 'Visualizza e aggiungi commenti',

      CancelIssuance: "Annullare l'emissione",
      CancelDeletion: 'Annulla eliminazione',

      Event: 'Evento',
      EventButton: 'Eliminazione richiesta',
      Status: 'Stato',
      SubmissionDate: 'Data di presentazione',
      Points: 'Punti',
      ExpiresAt: 'Scade il',
      TransactionId: 'ID transazione',
      AmountTransacted: 'Importo transato',
      TransactionDate: 'Data transazione',
      TransactionRef: 'Rif. transazione',
      Merchant: 'Commerciante',
      GlAccountNumber: 'Numero conto GL',
      CostCenterId: 'ID centro di costo',
      BranchCode: 'Codice filiale',

      ChangeStatus: 'Cambia stato',
      ChangeTier: 'Cambia livello',
      AddOrRemovePoints: 'Aggiungi / Rimuovi punti',

      ChangeStatusForm: {
        Active: 'Attivo',
        Inactive: 'Inattivo',
        Save: 'Salva'
      },
      ChangeTierForm: {
        Label: 'Sovrascrivi livello scade il:',
        Save: 'Salva'
      },
      PointsForm: {
        SelectGlAccount: 'Seleziona conto GL',
        GlAccountType: 'Tipo conto GL',
        GlAccountName: 'Nome conto GL',
        Message: 'Scrivi un messaggio per accompagnare questa regolazione punti.',
        AdjustPointsButton: 'Regola punti'
      },
      UpdateLoyaltyProgramCustomerStateSuccessMessage: 'Stato aggiornato',
      UpdateLoyaltyProgramCustomerStateErrorMessage: 'qualcosa è andato storto',
      UpdateLoyaltyProgramCustomerTierSuccessMessage: 'Livello aggiornato',
      UpdateLoyaltyProgramCustomerTierErrorMessage: 'qualcosa è andato storto',
      CreateCustomerLoyaltyTransaction: {
        GLAccountTypeRequiredMessage: 'È richiesto il tipo di conto GL',
        GlAccountNameRequiredMessage: 'È richiesto il nome del conto GL',
        MakerCheckerSuccessMessage: 'Richiesta di emissione punti inviata per approvazione',
        SuccessMessage: 'Transazione aggiunta',
        ErrorMessage: 'qualcosa è andato storto'
      },
      DeleteCustomerRequestLoyaltyTransaction: {
        SuccessMessage: 'Richiesta annullata',
        ErrorMessage: 'qualcosa è andato storto'
      },
      ApproveDenyCustomerRequestLoyaltyTransaction: {
        RequestHasBeenDeletedMessage: 'La richiesta è stata eliminata',
        RequestHasBeenApprovedMessage: 'La richiesta è stata approvata',
        ApproveSuccessMessage: 'La richiesta è stata ',
        ErrorMessage: 'qualcosa è andato storto'
      },
      DeleteCustomerLoyaltyTransaction: {
        MakerCheckerSuccessMessage:
          'Richiesta di eliminazione transazione inviata per approvazione',
        SuccessMessage: 'Transazione eliminata',
        ErrorMessage: 'qualcosa è andato storto'
      },
      RecoverCustomerLoyaltyTransaction: {
        SuccessMessage: 'Transazione recuperata',
        ErrorMessage: 'qualcosa è andato storto'
      },
      DeleteCustomerLoyaltyTransactionModal: {
        ActiveTitle: 'Inviare richiesta di eliminazione?',
        ActiveOkText: 'Richiedi eliminazione',
        ActiveCancelText: 'Annulla',
        CancelText: 'Indietro',
        ActiveMessage:
          'Sei sicuro di voler richiedere l’eliminazione di questi punti? Una volta approvato, non potrai ripristinarli.',
        MessageLineOne: `L'emissione di questi punti è in attesa di approvazione. Sei sicuro di voler annullare la richiesta?`,
        MessageLineTwo: 'Questa azione non può essere annullata.',

        CancelDeletionTitle: 'Annullare la richiesta di eliminazione punti?',
        Title: 'Annullare la richiesta di emissione punti?',
        OkText: 'Confermare',
        MessageLineOneDeletion: 'La cancellazione di questi punti è in attesa di approvazione. '
      },
      ApproveDenyModal: {
        DenyDeletionOkText: 'Nega',
        OkText: 'Approva',
        ApproveIssueTitle: "Approvare l'emissione di punti fedeltà",
        ApproveDeletionTitle: 'Approva la cancellazione dei punti fedeltà',
        DenyDeletionTitle: 'Nega la richiesta di eliminazione dei punti fedeltà',
        ApproveIssueAndDeletionMessageLineOne: 'Sei sicuro di volerlo approvare?',
        ApproveIssueAndDeletionMessageLineTwo: 'di punti fedeltà? ',
        Message: 'Sei sicuro di voler negare la cancellazione di questi punti fedeltà? ',
        Issuance: 'emissione',
        Deletion: 'cancellazione'
      },

      LineProgressBar: {
        HeaderDateText: 'inizio periodo',
        CustomerTierText: 'L’utente si trova attualmente in',
        Tier: 'Livello',
        CustomerForcedMinimumTierText: 'L’utente è stato forzatamente inserito nel livello minimo',
        CustomerForcedMaximumTierText: `L’utente è stato `,
        FORCED: 'FORZATO',
        into: 'in',
        EndOfPeriod: 'fine periodo',
        ForcedTier: 'Livello forzato',
        ForcedMinTier: 'Livello minimo forzato',
        UserHasBeen: 'L’utente è stato',
        Forced: 'forzato',
        To: 'a',
        Status: 'stato',
        Until: 'fino a',
        DeleteForcedTierLinkText: 'X Disattiva Livello Forzato',
        ToMinimum: 'al minimo',
        TheUserHasEarned: 'L’utente ha guadagnato',
        Points: 'Punto/i',
        DuringThisCycle: 'durante questo ciclo.',
        TheUserRequires: 'L’utente necessita',
        ToLevelUpTo: 'per salire al livello',
        TheUserHas: 'L’utente ha',
        Available: 'disponibile.'
      }
    },

    CustomerCampaigns: {
      ID: 'ID',
      HitThePinata: 'Colpisci la pentolaccia',
      ScratchCard: 'Gratta e vinci',
      Plinko: 'Plinko',
      Name: 'Nome',
      CampaignType: 'Tipo di campagna',
      Status: 'Stato',
      StartDate: 'Data di inizio',
      EndDate: 'Data di fine',
      Actions: 'Azioni',
      SearchCampaignsPlaceholder: 'Cerca campagne',
      UnenrollCustomer: 'Annulla iscrizione cliente',
      NotStarted: 'Non iniziata',
      Active: 'Attiva',
      Completed: 'Completata',
      NotCompleted: 'Non completata',
      Enrollable: 'Iscrivibile',
      EndedAndNotEnrolled: 'Terminata e non iscritto',
      Enrolled: 'Iscritto',
      NotEnrolled: 'Non iscritto',
      SpinTheWheel: 'Gira la ruota',
      ShakeTheTree: 'Scuoti l’albero',
      EnrollCustomer: 'Iscrivi cliente',
      SelectCampaignsPlaceholder: 'Seleziona campagne',

      SelectedTypeCampaigns: {
        Customers: 'Clienti',
        Campaigns: 'Campagne',
        StampCampaign: 'Campagna timbro',
        QuizCampaign: 'Campagna quiz',
        SurveyCampaign: 'Campagna sondaggio',
        GameCampaign: 'Campagna gioco',
        ProgressCampaign: 'Campagna progresso',
        QuestCampaign: 'Campagna missione',
        InstantRewardCampaign: 'Campagna ricompensa immediata',
        CampaignProgress: 'Questo cliente è attualmente su'
      },

      RaffleView: {
        TriggerName: 'Nome trigger',
        RaffleRuleID: 'ID regola lotteria',
        TransactionDetails: 'Dettagli transazione',
        TransactionRuleID: 'ID regola transazione',
        TriggeredDateOrTime: 'Data / Ora attivazione',
        RaffleCode: 'Codice lotteria',
        State: 'Stato'
      },

      InstantRewardView: {
        TriggerName: 'Nome trigger',
        RuleID: 'ID regola',
        TransactionDetails: 'Dettagli transazione',
        TriggeredDateOrTime: 'Data / Ora attivazione'
      },

      QuestView: {
        TriggerName: 'Nome trigger',
        Task: 'Compito',
        QuestRuleID: 'ID regola missione',
        TransactionDetails: 'Dettagli transazione',
        TransactionRuleID: 'ID regola transazione',
        TriggeredDateOrTime: 'Data / Ora attivazione',
        State: 'Stato',
        Actions: 'Azioni',
        MarkCompleted: 'Segna come completato',
        MarkCompletedSuccessMessage: 'Punti aggiornati con successo!',
        MarkCompletedErrorMessage: 'Errore nell’aggiornamento dei punti'
      },

      CustomerProgressCard: {
        TriggerName: 'Nome trigger',
        ProgressRuleID: 'ID regola progresso',
        TransactionDetails: 'Dettagli transazione',
        TransactionID: 'ID transazione',
        TriggeredDateOrTime: 'Data / Ora attivazione',
        Points: 'Punti'
      },

      CustomerGameView: {
        TriesID: 'ID tentativi',
        Status: 'Stato',
        AcquiredVia: 'Acquisito tramite',
        ReasonCode: 'Codice motivo',
        Email: 'Email',
        ID: 'ID',
        DateOfIssue: 'Data di emissione',
        DateOfExpiry: 'Data di scadenza',
        Outcomes: 'Risultati',
        VoucherID: 'ID voucher',
        NoOutcome: 'nessun risultato',

        SorrySomethingWentWrong: 'Siamo spiacenti, qualcosa è andato storto!',
        All: 'Tutto',
        Issued: 'Rilasciato',
        Redeem: 'Riscattare',
        Expired: 'Scaduto',
        Pending: 'In attesa di',
        PendingColumns: {
          RequestID: 'Richiesta ID',
          Status: 'Stato',
          Reason: 'Motivo',
          NumberOfTries: 'Numero di tentativi',
          DateOfRequest: 'Data della richiesta',
          Actions: 'Azioni'
        },
        ApproveIssue: 'Approvare il problema',
        ViewComments: 'Visualizza commenti',
        ViewAndAddComments: 'Visualizza e aggiungi commenti',
        ApproveIssueSuccessMessage: 'La richiesta è stata approvata',
        ApproveIssueErrorMessage: 'Qualcosa è andato storto',
        ApproveDenyModal: {
          Title: 'Approvare il problema dei premi',
          OkText: 'Approvare',
          CancelText: 'Cancellare',
          Description: "Sei sicuro di voler approvare l'emissione di questa ricompensa? "
        },
        CustomerCardView: {
          Slot: 'Slot n.',
          StampId: 'Timbro identificativo',
          Status: 'Stato',
          AcquiredVia: 'Acquisito tramite',
          StampIssuedDate: 'Data di emissione del timbro',
          StampRedeemedDate: 'Timbro riscatto della data',
          Outcomes: 'Risultati',
          RedeemTheStamp: 'Riscatta il timbro',
          EmptySlots: 'Slot vuoti',
          SlotEmpty: 'Slot vuoto',
          ID: 'ID',
          VoucherID: 'ID del buono',
          RewardID: 'Ricompensa ID',
          NoRewards: 'Nessun premio emesso',
          NoRewardsIssued: 'Nessun premio emesso',
          Note: 'Nota',
          Email: 'E-mail',
          SorrySomethingWentWrong: 'Scusa, qualcosa è andato storto'
        }
      },

      CustomerQuizAndSurveyView: {
        QuestionNo: 'Domanda n.',
        Question: 'Domanda',
        Response: 'Risposta',
        SubmissionDateOrTime: 'Data / Ora di invio',
        Result: 'Risultato'
      },

      IssueGameChangeModal: {
        MakerCheckerSuccessMessage:
          'Richiesta di emissione possibilità di gioco inviata per approvazione',
        SuccessMessage: 'Possibilità di gioco emesse con successo!',
        ErrorMessage: 'Errore nell’emissione delle possibilità di gioco',
        IssueGameTriesButton: 'Emetti tentativi di gioco',
        Title: 'Emetti possibilità di gioco a',
        OkText: 'Emetti possibilità',
        HowManyChances: 'Quante possibilità?',

        IssueGameChanceButton: 'Distribuisci la possibilità di gioco',
        ReasonForIssuance: "Motivo dell'emissione",
        ChanceCountValidationMessage: 'Si prega di specificare un conteggio delle possibilità.',
        ReasonForIssuanceValidationMessage: "Si prega di specificare un motivo per l'emissione."
      },

      CustomerStampCards: {
        CardHash: 'Carta #',
        StampCardID: 'ID carta timbro',
        State: 'Stato',
        ExpiryDate: 'Data di scadenza',
        DateOfParticipation: 'Data di partecipazione',
        StampsCollected: 'Timbri raccolti',
        CompletedDate: 'Data di completamento',
        RewardsAchieved: 'Ricompense raggiunte',

        PendingColumns: {
          RequestID: 'Richiesta ID',
          State: 'Stato',
          Reason: 'Motivo',
          NumberOfStamps: 'Numero di francobolli',
          DateOfRequest: 'Data della richiesta',
          Actions: 'Azioni'
        },
        CancelIssuance: "Annullare l'emissione",
        ApproveIssue: 'Approvare il problema',
        ApproveIssueSuccessMessage: 'La richiesta è stata approvata',
        ApproveIssueErrorMessage: 'Qualcosa è andato storto',
        ViewComments: 'Visualizza i commenti',
        Active: 'Attivo',
        Completed: 'Completato',
        Expired: 'Scaduto',
        Pending: 'In attesa di',
        SorrySomethingWentWrong: 'Scusa, qualcosa è andato storto'
      },

      IssueStampsModal: {
        MakerCheckerSuccessMessage: 'Richiesta di emissione timbri inviata per approvazione',
        SuccessMessage: 'Emissione timbri riuscita!',
        IssueStampsButton: 'Emetti timbri',
        Title: 'Emetti timbri a',
        OkText: 'Emetti timbri',
        HowManyStamps: 'Quanti timbri?',

        ErrorMessage: "Errore nell'emissione dei francobolli",
        CancelText: 'Cancellare',
        ReasonForIssuance: "Motivo per l'emissione",
        CustomerFullNameDefault: 'Cliente attuale',
        StampsCountValidationMessage: 'Si prega di specificare il numero di francobolli.',
        ReasonToIssuanceValidationMessage: "Si prega di specificare un motivo per l'emissione"
      },

      DeleteRequestModal: {
        SuccessMessage: 'La richiesta è stata annullata',
        ErrorMessage: 'Qualcosa è andato storto',
        Title: 'Cancellare {{type}} Richiesta di emissione?',
        OkText: 'Confermare',
        CancelText: 'Cancellare',
        DescriptionLineOne: 'Questo {{lowerCaseType}} è in attesa di approvazione. ',
        DescriptionLineTwo:
          'Una volta approvata la cancellazione, questo {{lowerCaseType}} non sarà disponibile e dovrai crearlo di nuovo.'
      },

      IssueRewardModal: {
        RewardIssuedSuccessfully: 'Ricompensa emessa con successo!',
        ErrorIssueReward: 'Errore nell’emissione della ricompensa',
        IssueRewardTitle: 'Emetti ricompense',
        IssueRewardOkText: 'Emetti ricompensa',
        IssueRewardButton: 'Emetti ricompensa',
        ReasonForAdjustment: 'Motivo dell’aggiustamento'
      },

      AddRemovePointsModal: {
        PointsUpdatedSuccessfully: 'Punti aggiornati con successo!',
        ErrorUpdatingPoints: 'Errore nell’aggiornamento dei punti',
        AddOrRemovePointsButton: 'Aggiungi/Rimuovi punti',
        AdjustProgressCampaignPoints: 'Regola punti campagna progresso',
        OkText: 'Regola punti',
        HowManyPointsToAddOrRemove: 'Quanti punti aggiungere/rimuovere?',
        ReasonForAdjustment: 'Motivo dell’aggiustamento'
      }
    },

    CustomerStamps: {
      All: 'Tutto',
      Active: 'Attivo',
      Inactive: 'Inattivo',
      Name: 'Nome',
      Status: 'Stato',
      Started: 'Iniziato',
      Ended: 'Finito',
      ID: 'ID',
      SearchPlaceholder: 'Trova una campagna per nome',
      EnrollCampaignModal: {
        SuccessMessage: 'La campagna ha registrato successo!',
        ErrorMessage: 'Campagna iscritta fallita.',
        CampaignIDValidationMessage: 'Specifica una campagna',
        FullNameDefault: 'Cliente attuale',
        ManualEnrollButton: 'Iscrizione manuale',
        Title: 'Iscriversi',
        OkText: 'Iscrivi cliente',
        CancelText: 'Cancellare'
      },

      CampaignSelectField: {
        Label: 'Seleziona Campagna',
        Edit: 'Modificare',
        Add: 'Aggiungere',
        Campaign: 'Campagna'
      },

      CampaignSelectModal: {
        Title: 'Seleziona una campagna',
        CancelText: 'Cancellare',
        OkText: 'Aggiungi campagna',
        SearchPlaceholder: 'Trova una campagna per nome, tipo o commerciante',
        Name: 'Nome',
        ID: 'ID',
        Status: 'Stato',
        Tags: 'Tag',
        StartDate: 'Data di inizio',
        EndDate: 'Data di fine'
      }
    },
    CustomerGames: {
      Name: 'Nome',
      ID: 'ID',
      Status: 'Stato',
      GameType: 'Tipo di gioco',
      TriesRedeemed: 'Prova riscattato',
      Started: 'Iniziato',
      Ended: 'Concluso',
      SearchPlaceholder: 'Trova una campagna per nome',
      SorrySomethingWentWrong: 'Scusa, qualcosa è andato storto',
      Tabs: {
        All: 'Tutto',
        Active: 'Attivo',
        Approved: 'Approvato',
        Inactive: 'Inattivo',
        Ended: 'Finito'
      }
    }
  }
};
