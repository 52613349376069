import { message } from 'antd';

import { DURATION_TIMER } from '../../appConstants';
import {
  ACTIVATE_CUSTOMER,
  ACTIVATE_CUSTOMER_FAIL,
  ACTIVATE_CUSTOMER_SUCCESS,
  DEACTIVATE_CUSTOMER,
  DEACTIVATE_CUSTOMER_FAIL,
  DEACTIVATE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_FAIL,
  DELETE_CUSTOMER_SUCCESS,
  GET_CUSTOMER,
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER_FAIL,
  GET_CUSTOMER_GAME_TRANSACTIONS,
  GET_CUSTOMER_PENDING_GAME_TRANSACTIONS,
  GET_CUSTOMER_PENDING_GAME_TRANSACTIONS_FAIL,
  GET_CUSTOMER_PENDING_GAME_TRANSACTIONS_SUCCESS,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_VOUCHERS,
  GET_CUSTOMER_VOUCHERS_FAIL,
  GET_CUSTOMER_VOUCHERS_PENDING_REQUEST,
  GET_CUSTOMER_VOUCHERS_PENDING_REQUEST_FAIL,
  GET_CUSTOMER_VOUCHERS_PENDING_REQUEST_SUCCESS,
  GET_CUSTOMER_VOUCHERS_SUCCESS,
  SEARCH_CUSTOMER,
  SEARCH_CUSTOMER_FAIL,
  SEARCH_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_SUCCESS
} from './constants';

type CallbackType = () => void;
type GetCustomerPayloadType = { id: string | number };
export const getCustomer = (payload: GetCustomerPayloadType, callBack?: CallbackType) => {
  return {
    type: GET_CUSTOMER,
    endpoint: `/v4/dash/customers/${payload.id}`,
    types: [GET_CUSTOMER, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAIL],
    notification: {
      loading: () => {
        message.loading('Loading form data...');
      },
      error: () => {
        message.error('Something Went Wrong!', DURATION_TIMER);
      }
    },
    params: payload,
    callback: callBack
  };
};

export const getCustomerFail = payload => {
  return {
    type: GET_CUSTOMER_FAIL,
    payload
  };
};

export const getCustomerSuccess = payload => {
  return {
    type: GET_CUSTOMER_SUCCESS,
    payload
  };
};

export const getCustomerVouchers = payload => {
  return {
    type: GET_CUSTOMER_VOUCHERS,
    payload
  };
};

export const getCustomerVouchersFail = payload => {
  return {
    type: GET_CUSTOMER_VOUCHERS_FAIL,
    payload
  };
};

export const getCustomerVouchersSuccess = payload => {
  return {
    type: GET_CUSTOMER_VOUCHERS_SUCCESS,
    payload
  };
};

export const getCustomerVouchersPendingRequest = payload => {
  return {
    type: GET_CUSTOMER_VOUCHERS_PENDING_REQUEST,
    payload
  };
};

export const getCustomerVouchersPendingRequestFail = payload => {
  return {
    type: GET_CUSTOMER_VOUCHERS_PENDING_REQUEST_FAIL,
    payload
  };
};

export const getCustomerVouchersPendingRequestSuccess = payload => {
  return {
    type: GET_CUSTOMER_VOUCHERS_PENDING_REQUEST_SUCCESS,
    payload
  };
};

export const getCustomers = payload => {
  return {
    type: GET_CUSTOMERS,
    payload
  };
};

export const getCustomersFail = payload => {
  return {
    type: GET_CUSTOMERS_FAIL,
    payload
  };
};

export const getCustomersSuccess = payload => {
  return {
    type: GET_CUSTOMERS_SUCCESS,
    payload
  };
};

export const updateCustomer = payload => {
  return {
    type: UPDATE_CUSTOMER,
    payload
  };
};

export const updateCustomerSuccess = payload => {
  return {
    type: UPDATE_CUSTOMER_SUCCESS,
    payload
  };
};
export const updateCustomerFail = payload => {
  return {
    type: UPDATE_CUSTOMER_FAIL,
    payload
  };
};

export const searchCustomer = payload => {
  return {
    type: SEARCH_CUSTOMER,
    payload
  };
};

export const searchCustomerSuccess = payload => {
  return {
    type: SEARCH_CUSTOMER_SUCCESS,
    payload
  };
};

export const searchCustomerFail = payload => {
  return {
    type: SEARCH_CUSTOMER_FAIL,
    payload
  };
};

export const activateCustomer = (payload, callback) => {
  return {
    type: ACTIVATE_CUSTOMER,
    endpoint: `/v4/dash/customers/${payload.id}/activate`,
    method: 'patch',
    types: [ACTIVATE_CUSTOMER, ACTIVATE_CUSTOMER_SUCCESS, ACTIVATE_CUSTOMER_FAIL],
    notification: {
      loading: () => message.loading('Activating customer ...'),
      success: response => {
        if (response && response?.data && response?.data?.request_type) {
          message.success('Customer Activation Request Submitted For Approval!');
        } else {
          message.success('Successfully Activated!');
        }
      },
      error: () => message.error('Activation failed')
    },
    nextAction:
      payload.page && payload.page === 'list'
        ? { type: GET_CUSTOMERS }
        : {
            type: GET_CUSTOMER,
            types: [GET_CUSTOMERS, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAIL],
            payload
          },
    formdata: payload,
    callback
  };
};

export const activateCustomerSuccess = payload => {
  return {
    type: ACTIVATE_CUSTOMER_SUCCESS,
    payload
  };
};

export const activateCustomerFail = payload => {
  return {
    type: ACTIVATE_CUSTOMER_FAIL,
    payload
  };
};

export const deactivateCustomer = (payload, callback) => {
  return {
    type: DEACTIVATE_CUSTOMER,
    endpoint: `/v4/dash/customers/${payload.id}/deactivate`,
    method: 'patch',
    types: [DEACTIVATE_CUSTOMER, DEACTIVATE_CUSTOMER_SUCCESS, DEACTIVATE_CUSTOMER_FAIL],
    params: { reason: payload.reason },
    notification: {
      loading: () => {
        message.loading('Deactivating customer...');
      },
      success: response => {
        if (response && response?.data && response?.data?.request_type) {
          message.success('Customer Deactivation Request Submitted For Approval!', DURATION_TIMER);
        } else {
          message.success('Successfully deactivated!', DURATION_TIMER);
        }
      },
      error: () => {
        message.error('Deactivation failed', DURATION_TIMER);
      }
    },
    nextAction:
      payload.page && payload.page === 'list'
        ? { type: GET_CUSTOMERS }
        : {
            type: GET_CUSTOMER,
            types: [GET_CUSTOMERS, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAIL],
            payload
          },
    formdata: payload,
    callback
  };
};

export const deactivateCustomerSuccess = payload => {
  return {
    type: DEACTIVATE_CUSTOMER_SUCCESS,
    payload
  };
};

export const deactivateCustomerFail = payload => {
  return {
    type: DEACTIVATE_CUSTOMER_FAIL,
    payload
  };
};

export const issueRewards = (payload, history?) => {
  return {
    type: 'ISSUE_REWARDS',
    payload,
    history
  };
};

export const issueVoucherSuccess = payload => {
  return {
    type: 'ISSUE_REWARDS_SUCCESS',
    payload
  };
};

export const deleteCustomer = (id, callback) => {
  return {
    type: DELETE_CUSTOMER,
    endpoint: `/v4/dash/customers/${id}/remove_pii`,
    method: 'patch',
    types: [DELETE_CUSTOMER, DELETE_CUSTOMER_SUCCESS, DELETE_CUSTOMER_FAIL],
    callback,
    notification: {
      loading: () => {
        message.loading('Deleting customer...');
      },
      success: response => {
        message.success('Successfully deleted!', DURATION_TIMER);
      },
      error: errorMessage => {
        let displayMessage = 'Something went wrong';
        if (errorMessage && typeof errorMessage === 'object' && errorMessage['message']) {
          displayMessage = errorMessage['message'];
        }
        if (errorMessage && typeof errorMessage === 'string') {
          displayMessage = errorMessage;
        }
        message.error(displayMessage, DURATION_TIMER);
      }
    }
  };
};

export const getCustomerGameTransactions = (gameId, params) => ({
  type: GET_CUSTOMER_GAME_TRANSACTIONS,
  payload: { gameId, params }
});

export const getCustomerGameTransactionsRequests = (gameId, params) => {
  return {
    type: GET_CUSTOMER_PENDING_GAME_TRANSACTIONS,
    endpoint: `/v4/dash/campaigns/${gameId}/game_transaction_requests`,
    types: [
      GET_CUSTOMER_PENDING_GAME_TRANSACTIONS,
      GET_CUSTOMER_PENDING_GAME_TRANSACTIONS_SUCCESS,
      GET_CUSTOMER_PENDING_GAME_TRANSACTIONS_FAIL
    ],
    params
  };
};
